import React, { FC, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { CollapsableProps } from './models';

import './styles.scss';

export const Collapsable: FC<CollapsableProps> = ({ header, text }) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <div className="collapsable">
      <button
        aria-controls="description-collapse-text"
        aria-expanded={isOpened}
        onClick={() => setIsOpened(!isOpened)}
        type="button"
        className="collapsable__header"
      >
        {header}
      </button>
      <Collapse in={isOpened}>
        <div
          id="description-collapse-text"
          className="collapsable__description"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Collapse>
    </div>
  );
};

export default Collapsable;
