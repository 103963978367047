import React, { FC } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import SectionName from 'common/SectionName';
import SectionTitle from 'common/SectionTitle';
import HowToUseItem from 'components/ProductPageComponents/HowToUseList/HowToUseItem';
import { HowToUseListProps } from './models';

import './HowToUseList.scss';

const HowToUseList: FC<HowToUseListProps> = ({ instructionsList, sectionName, title, text }) => (
  <>
    <Row data-testid="how-to-use-list">
      <Col lg={12}>
        <div className="how-to-use-list__wrapper">
          <SectionName text={sectionName} />
          <SectionTitle text={title} />
          <p className="how-to-use-list__text">{text}</p>
        </div>
      </Col>
    </Row>
    {instructionsList.map(({ properties }, index) => (
      <HowToUseItem key={properties.title} {...{ ...properties, index: index + 1 }} />
    ))}
  </>
);
export default HowToUseList;
