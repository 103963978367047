import React, { FC } from 'react';

import Collapsable from 'components/Collapsable';
import SectionName from 'common/SectionName';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { SafetyInformationProps } from './models';

import './SafetyInformation.scss';

const SafetyInformation: FC<SafetyInformationProps> = ({ textHeader, text, sectionName }) => (
  <Row data-testid="safety-information">
    <Col lg={12} className="safety-information__section">
      <SectionName text={sectionName} />
    </Col>
    <Col lg={12}>
      <Collapsable text={text} header={textHeader} />
    </Col>
  </Row>
);

export default SafetyInformation;
