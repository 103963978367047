import React, { FC } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'common/Image';

import { HowToUseItemProps } from './models';

import './HowToUseItem.scss';

const HowToUseItem: FC<HowToUseItemProps> = ({ image, imgAlt, text, title, index }) => {
  const columnOrder = index % 2 === 0 ? 'first' : index;

  return (
    <Row data-testid="how-to-use-item" className="how-to-use-item__row">
      <Col
        className="how-to-use-item__counter-wrapper"
        xs={{ span: 10, offset: 2 }}
        md={{ span: 5, offset: 1 }}
      >
        <div className="how-to-use-item__counter">{index}</div>
        <h6 className="how-to-use-item__title">{title}</h6>
        <p className="how-to-use-item__text">{text}</p>
      </Col>
      <Col xs={{ span: 10, offset: 2 }} md={{ span: 6, offset: 0, order: columnOrder }}>
        <Image className="how-to-use-item__image" imageData={image} alt={imgAlt} />
      </Col>
    </Row>
  );
};

export default HowToUseItem;
