import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';

import 'styles/main.scss';

import ProductInfo from 'components/ProductPageComponents/ProductInfo';
import FaqAccordion from 'components/Faq/FaqAccordion';
import HowToUseList from 'components/ProductPageComponents/HowToUseList';
import SafetyInformation from 'components/ProductPageComponents/SafetyInformation';
import RelatedArticles from 'components/RelatedArticles';
import Video from 'components/Video';

import './ProductPage.scss';

import createBreadcrumbs from 'utils/createBreadcrumbs';
import Layout from 'layout/Layout';
import { ProductPageProps } from './models';

const ProductPage: FC<ProductPageProps> = ({
  data: {
    allSitePage: { nodes },
    allProductPage: {
      nodes: [
        {
          seoMetaTitle,
          seoMetaDescription,
          seoMetaKeywords,
          seoNoIndex,
          productName,
          description,
          link,
          additionalLink,
          packshotImg,
          howToUseSection,
          faqSection: [{ properties: faqData }],
          safetyInformationSection,
          relatedArticles,
          video,
        },
      ],
    },
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
  },
  location: { pathname },
}) => {
  const breadcrumbs = createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname);

  return (
    <Layout
      headerTransparent
      breadcrumbs={breadcrumbs}
      seo={{ seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoNoIndex }}
    >
      <Container className="product-page page">
        <ProductInfo {...{ productName, packshotImg, description, link, additionalLink }} />
        {video?.length ? <Video videoId={video[0].properties.videoId} /> : null}
        {howToUseSection?.length ? <HowToUseList {...howToUseSection[0].properties} /> : null}
        {safetyInformationSection?.length ? (
          <SafetyInformation {...safetyInformationSection[0].properties} />
        ) : null}
        {relatedArticles?.length ? (
          <RelatedArticles
            className="product-page__related-articles"
            {...relatedArticles[0].properties}
          />
        ) : null}
        <FaqAccordion {...faqData} />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($url: String) {
    allSitePage {
      ...FragmentAllPageSite
    }
    allProductPage(filter: { url: { eq: $url } }) {
      nodes {
        ...SeoFragment
        link {
          name
          url
        }
        additionalLink {
          name
          url
        }
        productName
        description
        packshotImg {
          ...ImageFragment
        }
        safetyInformationSection {
          properties {
            sectionName
            textHeader
            text
          }
        }
        relatedArticles {
          ...RelatedArticlesFragment
        }
        howToUseSection {
          properties {
            title
            sectionName
            text
            instructionsList {
              properties {
                title
                text
                imgAlt
                image {
                  ...ImageFragment
                }
              }
            }
          }
        }
        faqSection {
          ...FaqAccordionFragment
        }
        video {
          ...VideoFragment
        }
      }
    }
  }
`;

export default ProductPage;
