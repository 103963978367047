import React, { FC } from 'react';
import { Link } from 'gatsby';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'common/Image';
import { ProductInfoProps } from './models';

import './ProductInfo.scss';

const ProductInfo: FC<ProductInfoProps> = ({
  packshotImg,
  productName,
  description,
  link,
  additionalLink,
}) => (
  <Row data-testid="product-info">
    <Col>
      <div className="product-info">
        <Image className="product-info__image" imageData={packshotImg} alt={productName} />
        <div className="product-info__wrapper">
          <h5 className="product-info__title">{productName}</h5>
          <div className="product-info__description">{description}</div>
          <div className="product-info__links">
            <Link className="product-info__link" to={link[0].url}>
              {link[0].name}
            </Link>
            {additionalLink?.length ? (
              <Link className="product-info__link product-info__link" to={additionalLink[0].url}>
                {additionalLink[0].name}
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    </Col>
  </Row>
);

export default ProductInfo;
